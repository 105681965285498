
import { Options, Vue } from 'vue-property-decorator'

import MainFooter from './MainFooter.vue'
import MainHeader from './MainHeader.vue'

@Options({
  name: 'MainLayout',
  components: {
    MainHeader,
    MainFooter
  }
})
export default class MainLayout extends Vue {}
