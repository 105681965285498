
import { Options, Vue } from 'vue-property-decorator'

import { MainLayout } from '@/views/layouts'

@Options({
  name: 'App',
  components: {
    MainLayout
  },
  head () {
    return {
      title: 'Fiók - RTL Magyarország',
      description:
        'Kezeld az RTL Magyarország fiókodat egy helyen.'
    }
  }
})
export default class App extends Vue {}
