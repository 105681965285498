
import { USER_GETTER, USER_NAMESPACE, UserData } from '@rtl/ui'
import { Options, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import MainLogo from '../components/icons/MainLogo.vue'

@Options({
  name: 'MainHeader',
  components: {
    MainLogo
  }
})

export default class MainHeader extends Vue {
  @Getter(USER_GETTER.USER, { namespace: USER_NAMESPACE })
  user!: UserData | null

  private isNavOpen = false
  private scrollPosition = 0
  private lastScrollPos = 0

  toggleMobileMenu (val?: boolean) {
    this.isNavOpen = val !== undefined ? val : !this.isNavOpen
    this.scrollPosition = window.pageYOffset
    const html = document.querySelector('html')
    const htmlElement = html as HTMLHtmlElement
    const body = document.querySelector('body')
    const bodyElement = body as HTMLBodyElement

    if (this.isNavOpen) {
      bodyElement.classList.add('menu-open', 'menu-open--user')
      this.lastScrollPos = this.scrollPosition
      htmlElement.style.overflow = 'hidden'
      htmlElement.style.position = 'fixed'
      htmlElement.style.top = `-${this.scrollPosition}px`
      htmlElement.style.width = '100%'
    } else {
      bodyElement.classList.remove('menu-open')
      htmlElement.style.removeProperty('overflow')
      htmlElement.style.removeProperty('position')
      htmlElement.style.removeProperty('top')
      htmlElement.style.removeProperty('width')
      window.scrollTo(0, this.lastScrollPos)
    }
  }
}
