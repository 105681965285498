import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "account-label auth__label" }
const _hoisted_2 = { class: "radio-button" }
const _hoisted_3 = ["value", "name"]
const _hoisted_4 = ["value", "name"]
const _hoisted_5 = {
  key: 0,
  class: "auth__field-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Exclamation = _resolveComponent("Exclamation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          value: _ctx.value1,
          name: _ctx.modelName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
        }, null, 8, _hoisted_3), [
          [_vModelRadio, _ctx.value]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.text1), 1)
      ]),
      _createElementVNode("label", null, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          value: _ctx.value2,
          name: _ctx.modelName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event))
        }, null, 8, _hoisted_4), [
          [_vModelRadio, _ctx.value]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.text2), 1)
      ])
    ]),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createVNode(_component_Exclamation),
          _createElementVNode("span", null, _toDisplayString(_ctx.errorMsg), 1)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}