import './hooks'

import { AppState } from '@rtl/ui'
import { createMemoryHistory, createRouter as createVueRouter, createWebHistory, Router } from 'vue-router'
import { Store } from 'vuex'

import { appGuard } from './guard'
import { routes } from './routes'

export function createRouter (isSSR = false) {
  const router = createVueRouter({
    history: isSSR ? createMemoryHistory() : createWebHistory(),
    routes
  })

  return router
}

export function createGuard (router: Router, store: Store<AppState>) {
  router.beforeEach(appGuard(store))
}
