
import { Vue } from 'vue-property-decorator'

export default class MainFooter extends Vue {
  openCookieBox () {
    if (typeof window.__tcfapi === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.__tcfapi('displayConsentUi', 2, function () { })
    }
  }
}
