import { AppState, USER_GETTER, USER_NAMESPACE } from '@rtl/ui'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { Store } from 'vuex'

export const appGuard = (store: Store<AppState>) => (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  if (to.meta.isAuthRequired && !store.getters[`${USER_NAMESPACE}/${USER_GETTER.USER}`]) {
    return next('/')
  }
  next()
}
