import { RouteRecordRaw } from 'vue-router'

export enum ROUTES {
  INDEX = 'index',
  ACCOUNT = 'account',
  PASSWORD = 'password',
  REGISTER = 'register',
  PERSONAL = 'personal',
  FINALIZE = 'finalize',
  CONFIRM = 'confirm',
  PASSWORD_CHANGE = 'passwordChange',
  PERSONAL_DATA = 'personalData',
  NEWSLETTER = 'newsletter',
  SOCIAL_PROCESS = 'socialProcess',
  FORGOT_PASSWORD = 'forgotPassword',
  SUCCESS_VERIFY = 'successVerify',
  NEW_PASSWORD = 'newPassword',
}

const LandingPage = () => import(/* webpackChunkName: "landing-page" */ '../views/pages/LandingPage.vue')
const AccountPage = () => import(/* webpackChunkName: "account-page" */ '../views/pages/AccountPage.vue')
const PasswordChangePage = () => import(/* webpackChunkName: "password-change-page" */ '../views/pages/PasswordChangePage.vue')
const RegisterPage = () => import(/* webpackChunkName: "register-page" */ '../views/pages/RegisterPage.vue')
const PersonalDataPage = () => import(/* webpackChunkName: "personal-data-page" */ '../views/pages/PersonalDataPage.vue')
const NewsletterPage = () => import(/* webpackChunkName: "newsletter-page" */ '../views/pages/NewsletterPage.vue')
const SocialProcessPage = () => import(/* webpackChunkName: "social-process-page" */ '../views/pages/SocialProcessPage.vue')
const ForgotPasswordPage = () => import(/* webpackChunkName: "forgot-password-page" */ '../views/pages/ForgotPasswordPage.vue')
const SuccessVerifyPage = () => import(/* webpackChunkName: "success-verify-page" */ '../views/pages/SuccessVerifyPage.vue')
const NewPasswordPage = () => import(/* webpackChunkName: "new-password-page" */ '../views/pages/NewPasswordPage.vue')

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: ROUTES.INDEX,
    component: LandingPage
  },
  {
    path: '/fiokom',
    name: ROUTES.ACCOUNT,
    meta: {
      isAuthRequired: true
    },
    component: AccountPage,
    children: [
      {
        path: '',
        name: 'personalDataDef',
        component: PersonalDataPage
      },
      {
        path: 'szemelyes',
        name: ROUTES.PERSONAL_DATA,
        component: PersonalDataPage
      },
      {
        path: 'jelszo',
        name: ROUTES.PASSWORD_CHANGE,
        component: PasswordChangePage
      },
      {
        path: 'hirlevel',
        name: ROUTES.NEWSLETTER,
        component: NewsletterPage
      }
    ]
  },
  {
    path: '/regisztracio/:social?',
    name: ROUTES.REGISTER,
    component: RegisterPage
  },
  {
    path: '/auth/social-process',
    name: ROUTES.SOCIAL_PROCESS,
    component: SocialProcessPage
  },
  {
    path: '/elfelejtett-jelszo',
    name: ROUTES.FORGOT_PASSWORD,
    component: ForgotPasswordPage
  },
  {
    path: '/sikeres-megerosites',
    name: ROUTES.SUCCESS_VERIFY,
    component: SuccessVerifyPage
  },
  {
    path: '/uj-jelszo',
    name: ROUTES.NEW_PASSWORD,
    component: NewPasswordPage
  }
]
